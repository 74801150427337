// Borders

.border {
    border: var(--border-width) solid var(--border-color) !important;
}

.border-top {
    border-top: var(--border-width) solid var(--border-color) !important;
}

.border-right {
    border-right: var(--border-width) solid var(--border-color) !important;
}

.border-bottom {
    border-bottom: var(--border-width) solid var(--border-color) !important;
}

.border-left {
    border-left: var(--border-width) solid var(--border-color) !important;
}

.border-0 {
    border: 0 !important;
}

.border-top-0 {
    border-top: 0 !important;
}

.border-right-0 {
    border-right: 0 !important;
}

.border-bottom-0 {
    border-bottom: 0 !important;
}

.border-left-0 {
    border-left: 0 !important;
}

@each $color, $value in $theme-colors {
    .border-#{$color} {
        border-color: $value !important;
    }
}
