:root {
    --progress-height: auto;
    --progress-font-size: 0.65625rem;
    --progress-background-color: #e9ecef;
    --progress-border-radius: var(--border-radius);
    --progress-box-shadow: inset 0 0.1rem 0.1rem rgba(var(--black), 0.1);
    --progress-bar-color: var(--white);
    --progress-bar-bg: var(--primary);
}

.progress {
    display: flex;
    height: var(--progress-height);
    overflow: hidden; // force rounded corners by cropping it
    line-height: 0;
    font-size: var(--progress-font-size);
    background-color: var(--progress-background-color);
    border-radius: var(--border-radius);
    box-shadow: var(--progress-box-shadow);
}

.progress-bar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    color: var(--progress-bar-color);
    text-align: center;
    white-space: nowrap;
    background-color: var(--progress-bar-bg);
    transition: width 0.6s ease;
}
