@import './colors.scss';
@import './variables.scss';

@import './functions.scss';
@import './mixins.scss';
@import './reboot';
@import './utilities';
@import 'wildcard/src/global-styles/breakpoints';
@import 'shared/src/global-styles/icons';
@import './dropdown';
@import './meter';
@import './popover';
@import './nav';
@import './list-group';
@import './custom-forms';
@import './typography';
@import './tables';
@import './code';
@import './input-group';
@import './forms';
@import './tabs';
@import './progress';
@import './collapse';
@import './grid';

// Show a focus ring when performing keyboard navigation. Uses the polyfill at
// https://github.com/WICG/focus-visible because few browsers support :focus-visible.
:focus:not(:focus-visible) {
    outline: none;
}
:focus-visible {
    outline: 0;
    box-shadow: var(--focus-box-shadow);
}

.cursor-pointer,
input[type='radio'],
input[type='checkbox'] {
    &:not(:disabled) {
        cursor: pointer;
    }
}
