:root {
    --custom-select-padding-y: var(--input-padding-y);
    --custom-select-padding-y-sm: var(--input-padding-y-sm);
    --custom-select-padding-y-lg: var(--input-padding-y-lg);
    --custom-select-padding-x: var(--input-padding-x);
    --custom-select-padding-x-sm: var(--input-padding-x-sm);
    --custom-select-padding-x-lg: var(--input-padding-x-lg);
    --custom-select-font-size: var(--font-size-base);
    --custom-select-bg-size: 1rem 1rem;
    --custom-select-background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23798baf' viewBox='0 0 24 24'%3e%3cpath d='M7.41 8.58L12 13.17l4.59-4.59L18 10l-6 6-6-6 1.41-1.42z'/%3e%3c/svg%3e")
        no-repeat right var(--custom-select-padding-x) center/var(--custom-select-bg-size);
    --custom-forms-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;
    --custom-select-height: calc(
        var(--input-line-height) * 1em + var(--input-padding-y) * 2 + var(--input-height-border)
    );
    --custom-select-height-lg: calc(
        var(--input-line-height-lg) * 1em + var(--input-padding-y-lg) * 2 + var(--input-height-border)
    );
}

// Select
//
// Replaces the browser default select with a custom one, mostly pulled from
// https://primer.github.io/.
//
.custom-select {
    display: inline-block;
    width: 100%;
    height: var(--custom-select-height);
    padding: var(--input-padding-y) calc(var(--input-padding-x) + 1rem) var(--input-padding-y) var(--input-padding-x);
    font-size: var(--custom-select-font-size);
    font-weight: var(--input-font-weight);
    line-height: var(--line-height-base);
    color: var(--input-color);
    vertical-align: middle;
    background: var(--input-bg) var(--custom-select-background);
    border: var(--input-border-width) solid var(--input-border-color);
    border-radius: 0.1875rem;
    appearance: none;
    transition: var(--custom-forms-transition);

    &:focus {
        border-color: var(--input-focus-border-color);
        outline: 0;
        box-shadow: var(--input-focus-box-shadow);

        &::-ms-value {
            // For visual consistency with other platforms/browsers,
            // suppress the default white text on blue background highlight given to
            // the selected option text when the (still closed) <select> receives focus
            // in IE and (under certain conditions) Edge.
            // See https://github.com/twbs/bootstrap/issues/19398.
            color: var(--input-color);
            background-color: var(--input-bg);
        }
    }

    &[multiple],
    &[size]:not([size='1']) {
        height: auto;
        padding-right: var(--custom-select-padding-x);
        background-image: none;
    }

    &:disabled {
        color: var(--input-color);
        background-color: var(--input-disabled-bg);
    }

    // Hides the default caret in IE11
    &::-ms-expand {
        display: none;
    }
}

.custom-select-sm {
    height: var(--input-height-sm);
    padding-top: var(--custom-select-padding-y-sm);
    padding-bottom: var(--custom-select-padding-y-sm);
    padding-left: var(--custom-select-padding-x-sm);
    font-size: 0.765625rem;
}

.custom-select-lg {
    height: var(--custom-select-height-lg);
    padding-top: var(--custom-select-padding-y-lg);
    padding-bottom: var(--custom-select-padding-y-lg);
    padding-left: var(--custom-select-padding-x-lg);
    font-size: 1.25rem;
}
