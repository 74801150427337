.text-monospace {
    font-family: var(--code-font-family) !important;
}

// Alignment

.text-justify {
    text-align: justify !important;
}

.text-wrap {
    white-space: normal !important;
}

.text-nowrap {
    white-space: nowrap !important;
}

.text-truncate {
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
}

// Responsive alignment

@each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
        $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

        .text#{$infix}-left {
            text-align: left !important;
        }
        .text#{$infix}-right {
            text-align: right !important;
        }
        .text#{$infix}-center {
            text-align: center !important;
        }
    }
}

// Transformation

.text-lowercase {
    text-transform: lowercase !important;
}

.text-uppercase {
    text-transform: uppercase !important;
}

.text-capitalize {
    text-transform: capitalize !important;
}

// Weight and italics

.font-weight-normal {
    font-weight: var(--font-weight-normal) !important;
}

.font-weight-bold {
    font-weight: var(--font-weight-bold) !important;
}

.font-italic {
    font-style: italic !important;
}

// Contextual colors

.text-white {
    color: var(--white) !important;
}

@each $color, $value in $theme-colors {
    .text-#{$color} {
        color: $value !important;
    }

    a.text-#{$color} {
        &:hover,
        &:focus {
            color: darken($value, 15%) !important;
        }
    }
}

.text-body {
    color: var(--body-color) !important;
}

.text-muted {
    color: var(--text-muted) !important;
}

// Misc

.text-decoration-none {
    text-decoration: none !important;
}

.text-break {
    word-break: break-word !important; // IE & < Edge 18
    overflow-wrap: break-word !important;
}
