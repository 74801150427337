meter {
    --meter-background: var(--border-color-2);

    .theme-dark & {
        --meter-background: var(--gray-07);
    }
    .theme-light & {
        --meter-background: var(--gray-04);
    }

    /* Reset the default appearence */
    -moz-appearance: none;
    // Don't reset -webkit-appearance: https://bugs.chromium.org/p/chromium/issues/detail?id=632510

    /* We can directly style the meter's background for Firefox */
    background: none;
    background-color: var(--meter-background);
    border-radius: 1px;
    height: 0.375rem;

    &::-moz-meter-bar {
        background: none;
        border-radius: 1px;
    }

    /* We have to style this pseudo-element for Chrome */
    &::-webkit-meter-bar {
        height: 0.375rem;
        border: none;
        border-radius: 1px;
        background-color: var(--meter-background);
    }

    // Optimum value styles
    &:-moz-meter-optimum::-moz-meter-bar {
        background-color: var(--success);
    }

    &::-webkit-meter-optimum-value {
        background-color: var(--success);
    }

    // Suboptimum value styles
    &:-moz-meter-sub-optimum::-moz-meter-bar {
        background-color: var(--warning);
    }

    &::-webkit-meter-sub-optimum-value {
        background-color: var(--warning);
    }

    // Horrible value styles
    &:-moz-meter-sub-sub-optimum::-moz-meter-bar {
        background-color: var(--danger);
    }

    &::-webkit-meter-even-less-good-value {
        background-color: var(--danger);
    }
}
