/*
This is the main entry point for all styles for the web app.
It should import all component stylesheets.
*/

// Global libraries styles
@import 'react-grid-layout/css/styles.css';
@import 'react-resizable/css/styles.css';

// Use duplicate selectors for the light-theme
// stylelint-disable no-duplicate-selectors

@import '../../branded/src/global-styles/index.scss';

// stylelint-disable-next-line selector-max-id
html,
body,
#root {
    height: 100%;
}

// Document highlight is the background color for tokens which are matched with
// a result from a document highlight provider. e.g. for references of the token
// currently being hovered over.
//
// Note that we define this before selection highlights so that it will override
// the styles of symbol that has both classes applied.
.sourcegraph-document-highlight {
    background-color: var(--secondary);
}

// Token link is used when we have converted token ranges into links within the blob view
// This primarily removes some default link styles and adds a more suitable focus style.
.sourcegraph-token-link {
    color: inherit;
    text-decoration: inherit;

    &:focus {
        background-color: rgba($blue, 0.1);
    }
}

// Selection highlight is the background color for matched/highlighted tokens,
// e.g. for search results, for identifying the token currently being hovered over,
// or identifying the token the references panel is toggled for.
.selection-highlight,
.selection-highlight-sticky {
    background-color: var(--mark-bg);
}

.match-highlight,
.match-highlight-sticky {
    background-color: var(--mark-bg);
}

// When a match highlight and a selection highlight overlap,
// change the color to be able to distinguish between the highlights.
.match-highlight .selection-highlight,
.match-highlight.selection-highlight,
.selection-highlight .match-highlight {
    .theme-light & {
        background-color: var(--mark-bg-light);
    }

    .theme-dark & {
        background-color: var(--mark-bg-dark);
    }
}

.spacer {
    flex: 1;
}

.flex-1 {
    flex: 1;
}

.flex-bounded {
    flex: 1;
    min-width: 0;
}

.flex-shrink-past-contents {
    flex-shrink: 1;
    min-width: 0;
}

// Global styles provided by @reach packages. Should be imported once in the global scope.
@import '@reach/combobox/styles.css';
@import '@reach/menu-button/styles.css';

// Pages
@import './api/ApiConsole';

:root {
    --max-homepage-container-width: 65rem;
    // Skip Reach UI styles. See https://reacttraining.com/reach-ui/styling/.
    --reach-menu-button: 1;
    --reach-accordion: 1;
    // Media breakpoints
    --media-sm: 576px;
    --media-md: 768px;
    --media-lg: 992px;
    --media-xl: 1200px;
}
